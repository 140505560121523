import React from 'react';
import {IEvent, IEventOld} from "../../models/Event";
import dayjs from "dayjs";
import DateBox from "./DateBox";
import DetailsBox from "./DetailsBox";

interface IBoxRowProps {
    event: IEvent
    displaySide: "right" | "left"
    onImagePress: () => void
}

function getDateString(timestamp: number|undefined){
    if(!timestamp) return "";
    return dayjs(timestamp).format("DD/MM/YYYY");
}

const BoxRow:React.FC<IBoxRowProps> = ({event, displaySide, onImagePress}) => {

    const isLeft = displaySide === "left";
    const {eventDay, eventMonth, eventYear} = event;
    const dayMonth = event.eventDay && event.eventMonth ? `${eventDay}, ${eventMonth}` : eventMonth;
    /*const dateDisplay = getDateString(event.dateOfEvent);
    const year = dayjs(event.dateOfEvent).year();
    const dayMonth = dayjs(event.dateOfEvent).format("DD, MMMM");*/

    return (
        <div className="row">
            <div className="left-description">
                {isLeft ? <DetailsBox title={event.title} description={event.description} displaySide={displaySide}/> : null}
            </div>
            <div className="left-image">
                {isLeft ? <img onClick={onImagePress} src={`data:image/jpeg;base64,${event.image}`} alt="Thumbnail" className="timeline-image"/> : <DateBox year={eventYear} dayMonth={dayMonth}/>}
            </div>
            <div className="right-image">
                {isLeft ? <DateBox year={eventYear} dayMonth={dayMonth}/> : <img onClick={onImagePress} src={`data:image/jpeg;base64,${event.image}`} alt="Thumbnail" className="timeline-image"/>}
            </div>
            <div className="right-description">
                {!isLeft ? <DetailsBox title={event.title} description={event.description} displaySide={displaySide}/> : null}
            </div>
        </div>
    );
};

export default BoxRow;
