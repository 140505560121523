import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="rowfooter">
                <div>
                    <h3 className="">Website made by Josh Carse & Cassie Carse</h3>
                    <p>Help from Ken Carse (our Dad)</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
