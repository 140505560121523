import React from 'react';
import './checkbox.css';

interface ICheckboxProps {
    label: string
    checked: boolean
    onCheckedChanged: (checked: boolean) => void
}

const Checkbox: React.FC<ICheckboxProps> = ({label, checked, onCheckedChanged}) => {
    return (
        <div className="checkbox-container">
            <input type="checkbox" id={label} name={label} checked={checked} onChange={(e) =>  onCheckedChanged(e.currentTarget.checked)}/>
            <label htmlFor={label}>{label}</label>
        </div>
    );
};

export default Checkbox;