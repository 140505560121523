import React, {ChangeEvent, useState} from 'react';
import './uploadPage.css';
import TextInput from "../../components/TextInput/TextInput";
import CategoriesCheckboxList from "./CategoriesCheckboxList";
import TextArea from "../../components/TextInput/TextArea";
import UploadArea from "../../components/UploadArea/UploadArea";
import dayjs from "dayjs";
import {Utils} from "../../helper/utils";
import {Consts, CreatedByCode} from "../../helper/consts";
import {IEvent} from "../../models/Event";
import {Http} from "../../helper/Http";
import DropDownList from "../../components/DropDownList/DropDownList";
import Checkbox from "../../components/Checkbox/Checkbox";
import * as ImgUtil from 'image-conversion';

export type Categories =  {"Young Betty": boolean, Friends: boolean, Work: boolean, Marriage: boolean, Children: boolean, Grandchildren: boolean, Family: boolean};

const UploadPage = () => {

    const [createdByCode, setCreatedByCode] = useState<string>("");
    const [enteredCreatedByCode, setEnteredCreatedByCode] = useState<string>("");

    const [title, setTitle] = useState("");
    const [showOnTimeline, setShowOnTimeline] = useState(false);
    const [description, setDescription] = useState("");
    // const [dateOfEvent, setDateOfEvent] = useState("");
    const [eventDay, setEventDay] = useState("");
    const [eventMonth, setEventMonth] = useState("");
    const [eventYear, setEventYear] = useState("");
    const [categories, setCategories] = useState<Categories>({
        "Young Betty": false,
        "Friends": false,
        "Work": false,
        "Marriage": false,
        "Children": false,
        "Grandchildren": false,
        "Family": false
    });
    const [caption, setCaption] = useState("");

    const [files, setFiles] = useState<Blob[]>([]);


    const fileSelectedHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        const eventFiles = e.target.files;
        const newFiles: Blob[] = [];
        if(!eventFiles)
            return;
        for(let i = 0; i < eventFiles.length; i++){
            const file = eventFiles.item(i);
            if(file){
                const compressedImage = await ImgUtil.compressAccurately(file,400);
                newFiles.push(compressedImage);
            }
        }
        setFiles([...files, ...newFiles]);
    }

    const commaSeparatedCategories = () => {
        let result = "";
        for (let key in categories) {
            // @ts-ignore
            if (categories.hasOwnProperty(key) && categories[key]) {
                // @ts-ignore
                result += ", " + key;
            }
        }
        if(!result)
            return result;
        return result.substring(2);
    }

    const handleSubmit = async () => {
        if(!eventYear || eventYear.includes(" ")){
            alert("Event year is required");
            return;
        }

        let base64 = await Utils.getBase64(files[0]);
        if(typeof base64 === "string")
            base64 = base64.substring(base64.indexOf(",") + 1);
        else{
            alert("something went wrong uploading this image");
            return;
        }
        // console.log("base 64: ", base64)
        const newEvent: IEvent = {
            title,
            description,
            // dateOfEvent: newDateOfEvent,
            eventDay: eventDay.includes(" ") ? undefined : eventDay,
            eventMonth: eventMonth.includes(" ") ? undefined : eventMonth,
            eventYear,
            caption,
            categories: commaSeparatedCategories(),
            createdByCode,
            image: base64,
            showOnTimeline: showOnTimeline
        }

        const result = await Http.post("Events/", newEvent);

        setFiles([...files.slice(1)]);
        setTitle("");
        setDescription("")
        // setShowOnTimeline(false);
        setDescription("")
        setEventDay("");
        setEventMonth("");
        setEventYear("");
        setCategories({
            "Young Betty": false,
            "Friends": false,
            "Work": false,
            "Marriage": false,
            "Children": false,
            "Grandchildren": false,
            "Family": false
        });
        setCaption("");
    }

    const handleEnteredByCreatedCodeChanged = (text: string) => {
        if(Object.values(CreatedByCode).includes(text)){
            setCreatedByCode(text);
            text = "";
        }
        setEnteredCreatedByCode(text);
    }


    return (
        <div className="upload-page-container">
            {!createdByCode?.length && <TextInput value={enteredCreatedByCode} onChange={handleEnteredByCreatedCodeChanged} label={"Code"}/>}
            {files.length === 0 && createdByCode?.length > 0 && <UploadArea onFilesUploaded={fileSelectedHandler}/>}
            {files.length !== 0 && (
                <div className="upload-form-container">

                    <div className="image-container">
                        <img src={URL.createObjectURL(files[0])}/>
                    </div>
                    <Checkbox label="Show on Timeline?" checked={showOnTimeline} onCheckedChanged={setShowOnTimeline}/>
                    <TextInput value={title} onChange={setTitle} label={"Title"}/>
                    <TextArea value={description} onChange={setDescription} label={"Description"}/>
                    {/*<TextInput value={dateOfEvent} onChange={setDateOfEvent} label={"Date of Event"} placeHolder="Format DD/MM/YYYY"/>*/}
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <DropDownList values={Consts.DAYS} onValueChanged={setEventDay}/>
                        <DropDownList values={Consts.MONTHS} onValueChanged={setEventMonth}/>
                        <DropDownList values={Consts.YEARS} onValueChanged={setEventYear}/>
                    </div>
                    <CategoriesCheckboxList categories={categories} setCategories={setCategories}/>
                    <TextInput value={caption} onChange={setCaption} label={"Who is in this photo?"} placeHolder="Who is in this photo?"/>
                    <button onClick={handleSubmit}>{files.length > 1 ? "Next" : "Submit"}</button>
                    <button style={{backgroundColor: "#dc3545", marginTop: "10px"}} onClick={() => setFiles([...files.slice(1)])}>Skip this image</button>
                </div>
            )}

        </div>
    );
};

export default UploadPage;
