import React, {useEffect, useState} from 'react';
import {useEvents} from "../../providers/EventsProvider";
import './timelineBoxStyles.css';
import BoxRow from "./BoxRow";
import {useTimelineEvents} from "../../providers/TimelineEventsProvider";
import ImageModal from "../ImageModal";
import {IEvent} from "../../models/Event";

const TimelineBox = () => {

    const {events, loaded} = useTimelineEvents();
    const [selectedEvent, setSelectedEvent] = useState<IEvent | undefined>();

    useEffect(() => {
        console.log("events length", events.length);
    }, [events]);

    return (
        <div className="container">
            {!loaded
                ? <div>Loading</div>
                : events.map((e, i) => <BoxRow key={e.id} event={e} onImagePress={() => setSelectedEvent(e)} displaySide={i % 2 === 0 ? "left" : "right"}/>)
            }
            {selectedEvent && (
                <ImageModal
                    event={selectedEvent}
                    image={`data:image/jpeg;base64,${selectedEvent.image}`}
                    onClosePressed={() => setSelectedEvent(undefined)}
                    onPreviousPressed={() => setSelectedEvent(events.find((x, i) => i === events.indexOf(selectedEvent)-1) ?? events[events.length - 1])}
                    onNextPressed={() => setSelectedEvent(events.find((x, i) => i === events.indexOf(selectedEvent)+1) ?? events[0])}
                />
            )}
        </div>
    );
};

export default TimelineBox;
