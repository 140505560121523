import React, {useState} from 'react';
import '../UploadPage/uploadPage.css';
import TextInput from "../../components/TextInput/TextInput";
import TextArea from "../../components/TextInput/TextArea";
import {Consts} from "../../helper/consts";
import {IEvent, IUpdateEvent} from "../../models/Event";
import {Http} from "../../helper/Http";
import DropDownList from "../../components/DropDownList/DropDownList";
import Checkbox from "../../components/Checkbox/Checkbox";
import CategoriesCheckboxList from "../UploadPage/CategoriesCheckboxList";
import {Categories} from "../UploadPage/UploadPage";
import {useNavigate} from "react-router-dom";

interface IUpdatePageProps {
    event: IEvent
    onSubmit: () => void
    onCancel: () => void
}

const UpdatePage: React.FC<IUpdatePageProps> = ({event, onSubmit, onCancel}) => {

    const navigate = useNavigate();

    const [title, setTitle] = useState(event.title ?? "");
    const [showOnTimeline, setShowOnTimeline] = useState(!!event.showOnTimeline ?? "");
    const [description, setDescription] = useState(event.description ?? "");
    const [eventDay, setEventDay] = useState(event.eventDay ?? "");
    const [eventMonth, setEventMonth] = useState(event.eventMonth ?? "");
    const [eventYear, setEventYear] = useState(event.eventYear ?? "");
    const [categories, setCategories] = useState<Categories>({
        "Young Betty": event.categories.includes("Young Betty"),
        "Friends": event.categories.includes("Friends"),
        "Work": event.categories.includes("Work"),
        "Marriage": event.categories.includes("Marriage"),
        "Children": event.categories.includes("Children"),
        "Grandchildren": event.categories.includes("Grandchildren"),
        "Family": event.categories.includes("Family")
    });
    const [caption, setCaption] = useState(event.caption ?? "");

    const commaSeparatedCategories = () => {
        let result = "";
        for (let key in categories) {
            // @ts-ignore
            if (categories.hasOwnProperty(key) && categories[key]) {
                // @ts-ignore
                result += ", " + key;
            }
        }
        if(!result)
            return result;
        return result.substring(2);
    }

    const handleSubmit = async () => {
        if(!eventYear || eventYear.includes(" ")){
            alert("Event year is required");
            return;
        }

        // console.log("base 64: ", base64)
        const updateEvent: IUpdateEvent = {
            id: event.id!,
            title,
            description,
            eventDay: eventDay.includes(" ") ? undefined : eventDay,
            eventMonth: eventMonth.includes(" ") ? undefined : eventMonth,
            eventYear,
            caption,
            categories: commaSeparatedCategories(),
            showOnTimeline: showOnTimeline
        }

        const result = await Http.put("Events/", updateEvent);
        console.log(result);

        setTitle("");
        setDescription("")
        // setShowOnTimeline(false);
        setDescription("")
        setEventDay("");
        setEventMonth("");
        setEventYear("");
        setCategories({
            "Young Betty": false,
            "Friends": false,
            "Work": false,
            "Marriage": false,
            "Children": false,
            "Grandchildren": false,
            "Family": false
        });
        setCaption("");
        onSubmit();
    }


    return (
        <div className="upload-page-container">
            <div className="upload-form-container">
                <div className="image-container">
                    <img src={`data:image/jpeg;base64,${event.image}`}/>
                </div>
                <Checkbox label="Show on Timeline?" checked={showOnTimeline} onCheckedChanged={setShowOnTimeline}/>
                <TextInput value={title} onChange={setTitle} label={"Title"}/>
                <TextArea value={description} onChange={setDescription} label={"Description"}/>
                {/*<TextInput value={dateOfEvent} onChange={setDateOfEvent} label={"Date of Event"} placeHolder="Format DD/MM/YYYY"/>*/}
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <DropDownList values={Consts.DAYS} onValueChanged={setEventDay} value={eventDay}/>
                    <DropDownList values={Consts.MONTHS} onValueChanged={setEventMonth} value={eventMonth}/>
                    <DropDownList values={Consts.YEARS} onValueChanged={setEventYear} value={eventYear}/>
                </div>
                <CategoriesCheckboxList categories={categories} setCategories={setCategories}/>
                <TextInput value={caption} onChange={setCaption} label={"Who is in this photo?"} placeHolder="Who is in this photo?"/>
                <button onClick={handleSubmit}>Update</button>
                <button style={{backgroundColor: "#dc3545", marginTop: "10px"}} onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default UpdatePage;
