import React, {CSSProperties, useState} from 'react';
import {useEvents} from "../providers/EventsProvider";
// import 'react-vertical-timeline-component/style.min.css';
// import TimelineCard from "./TimelineCard";
import dayjs from "dayjs";
import {Consts} from "../helper/consts";
import TimelineHexagons from "./TimelineHexagons/TimelineHexagons";
import TimelineBox from "./TimelineBox/TimelineBox";


// What about instead of absolute positioning, try having rows like 10vh height
// then have the year at the top and can just relative position move that 50% up/ down to make it right.
// will mean you can just put all the cards in the row and do the width = 33% if there are three cards kind of thing.
// Probably just get the event one after each other first so that it's like a working copy. then can try the harder year after each other


const Timeline = () => {

    const {events} = useEvents();

    const [years, ] = useState(Array.from({length:100},(v,k)=>k+1922));
    const [leftSide, ] = useState(events.filter((x, i) => i % 2 === 0));
    const [rightSide, ] = useState(events.filter((x, i) => i % 2 !== 0));

    return <TimelineBox/>;
    // return <TimelineHexagons/>
/*
    return (
        <div style={styles.timelineContainer}>

            {years.map((y, i) => (
                <div key={i.toString()} className={"timeline-year-elements"} style={{top: ((i+1) * Consts.TIME_LINE_LENGTH_ONE_YEAR) + "vh"}}>
                    <span>{y}</span>
                </div>
            ))}

            <div style={{...styles.timelineContainerColumn, ...styles.timelineContainerLeft}}>
                {leftSide.map(x => {
                    const vh = ((dayjs(x.dateOfEvent).year() - 1922) * Consts.TIME_LINE_LENGTH_ONE_YEAR) + "vh";
                    const top = `calc(${vh} + ${Consts.YEAR_TAG_HEIGHT}px)`;

                    return <TimelineCard key={x.title} event={x} columnSide={"left"} top={top}/>;
                    // return <TimelineCard key={x.title} event={x} columnSide={"left"} top={((dayjs(x.dateOfEvent).year() - 1922) * 10) + "vh"}/>;
                })}
            </div>
            <div style={{...styles.timelineContainerColumn, ...styles.timelineContainerRight}}>

            </div>
        </div>
    );

    return (
        <div style={styles.timelineContainer}>
            <div style={{...styles.timelineContainerColumn, ...styles.timelineContainerLeft}}>
                {leftSide.map(x => {
                    return <TimelineCard key={x.title} event={x} columnSide={"left"} top={((dayjs(x.dateOfEvent).year() - 1922) * Consts.TIME_LINE_LENGTH_ONE_YEAR) + "vh"}/>;
                })}
            </div>
            <div style={{...styles.timelineContainerColumn, ...styles.timelineContainerRight}}>
                {rightSide.map(x => {
                    return <TimelineCard key={x.title} event={x} columnSide={"right"} top={((dayjs(x.dateOfEvent).year() - 1922) * Consts.TIME_LINE_LENGTH_ONE_YEAR) + "vh"}/>;
                })}
            </div>
        </div>
    );

    */
};

const styles: { [key: string]: CSSProperties; } = {
    timelineContainer2: {
        height: "100%",
        width: "100%",
        // borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    },
    timelineContainer: {
        height: Consts.TIME_LINE_LENGTH_STYLE,
        width: "100vw",
        // borderWidth: 1,
        // borderColor: "black",
        // borderStyle: "solid",
        display: "flex",
        justifyContent: "space-between"
    },
    timelineContainerColumn: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between"
    },
    timelineContainerLeft: {
        // alignSelf: "flex-end",
        // borderLeft: "solid black 1px",
        borderRightColor: "black",
        borderRightStyle: "solid",
        width: "50%",
    },
    timelineContainerRight: {
        // alignSelf: "flex-end",
        width: "50%",
    }
}

export default Timeline;