import React, {useEffect, useState} from 'react';
import {useEvents} from "../../providers/EventsProvider";
import './deletePage.css'
import {Http} from "../../helper/Http";
import TextInput from "../../components/TextInput/TextInput";
import {CreatedByCode} from "../../helper/consts";
import {IEvent} from "../../models/Event";
import UpdatePage from "../UpatePage/UpdatePage";
import {useNavigate} from "react-router-dom";

const DeletePage = () => {
    const {events: requestedEvents, loaded} = useEvents();
    const [events, setEvents] = useState(requestedEvents);
    const [createdByCode, setCreatedByCode] = useState<string>("");
    const [enteredCreatedByCode, setEnteredCreatedByCode] = useState<string>("");
    const [editingEvent, setEditingEvent] = useState<IEvent | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        setEvents(requestedEvents);
    }, [requestedEvents])

    const handleEnteredByCreatedCodeChanged = (text: string) => {
        if(Object.values(CreatedByCode).includes(text)){
            setCreatedByCode(text);
            text = "";
        }
        setEnteredCreatedByCode(text);
    }

    const handleDelete = (id: string) => {
        let confirmAction = window.confirm("Are you sure you want to delete this event?");
        if (confirmAction) {
            setEvents(events.filter(x => x.id !== id));
            Http.delete("Events/"+ id)
        } else {
            alert("Action canceled");
        }
    }

    if(!loaded && createdByCode?.length) {
        return (
            <div className="container">
                <div>Loading</div>
            </div>
        );
    }

    const handleEditSubmit = () => {
        if(!editingEvent)
            return
        setEvents(events.filter(x => x.id !== editingEvent.id));
        setEditingEvent(null);
    }

    if(editingEvent)
        return <UpdatePage event={editingEvent} onSubmit={handleEditSubmit} onCancel={() => setEditingEvent(null)}/>

    return (
        <div style={{margin: "20px"}}>
            {!createdByCode?.length && <TextInput value={enteredCreatedByCode} onChange={handleEnteredByCreatedCodeChanged} label={"Code"}/>}
            {createdByCode?.length > 0 && events.map((event, i) => (
                <div key={event.id!.toString()} style={{display: "flex", alignItems: "center", marginBottom: "20px", borderWidth: "1px", borderStyle: "solid", borderRadius: "10px"}}>
                    <img src={`data:image/jpeg;base64,${event.image}`} alt="Thumbnail" className="timeline-image"/>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Id: {event.id}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Tags: {event.categories}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Title: {event.title}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Description: {event.description}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Year: {event.eventYear}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Who: {event.caption}</span>
                        <span style={{marginLeft: "20px", marginRight: "20px"}}>Timeline: {event.showOnTimeline ? "true" : "false"}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <button className="danger-button" onClick={() => handleDelete(event.id!)}>Delete</button>
                        <button className="danger-button" style={{backgroundColor: "#35A2DCFF"}} onClick={() => setEditingEvent(event)}>Edit</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DeletePage;
