import {ApiErrorResponse, ApiOkResponse, ApiResponse, create} from 'apisauce';
import {AxiosRequestConfig} from "axios";
import {Settings} from "./settings";

const apiClient = create({
    baseURL: Settings.apiUrl,
    timeout: 120000,
});

/*
apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await AuthStorage.getToken();
    if(!authToken)
        return;
    request.headers["x-auth-token"] = authToken;
});*/


const get = apiClient.get;
const post = apiClient.post;

export type RequestMethod = <T>(url: string, params?: ({} | undefined), axiosConfig?: (AxiosRequestConfig | undefined)) => Promise<ApiResponse<T, T>>;
export const Http = {
    get: async <T>(url: string, params?: {}, axiosConfig?: AxiosRequestConfig):  Promise<ApiResponse<T, T>> => {
        return await get(url, params, axiosConfig);
    },
    post: async <T>(url: string, data?: {}, axiosConfig?: AxiosRequestConfig):  Promise<ApiResponse<T, T>> => {
        return await post(url, data, axiosConfig);
    },
    delete: async <T>(url: string, data?: {}, axiosConfig?: AxiosRequestConfig):  Promise<ApiResponse<T, T>> => {
        return await apiClient.delete(url, data, axiosConfig);
    },
    put: async <T>(url: string, data?: {}, axiosConfig?: AxiosRequestConfig):  Promise<ApiResponse<T, T>> => {
        return await apiClient.put(url, data, axiosConfig);
    }
}
