import React, {useEffect, useState} from 'react';
import {IEvent} from "../../models/Event";
import ImageModal from "../../components/ImageModal";

interface IChapterProps {
    allEvents: IEvent[]
    tags: string[]
    label: string
    noTags?: boolean
}

const Chapter: React.FC<IChapterProps> = ({allEvents, tags, label, noTags}) => {

    const [events, setEvents] = useState<IEvent[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<IEvent | undefined>();

    useEffect(() => {
        if(allEvents.length > 0){
            const filteredEvents = allEvents.filter(e => {
                if(noTags)
                    return e.categories.length === 0
                return tags.every(t => {
                    return e.categories.toLowerCase().includes((() => {
                        return t.toLowerCase();
                    })());
                });
            });
            setEvents(filteredEvents)
            // setEvents(allEvents.filter(e => tags.every(t => e.categories.toLowerCase().includes(t.toLowerCase()))))
            // setEvents(allEvents.filter(e => tags.every(t => e.categories.toLowerCase().includes(t.toLowerCase()))))
        }
    }, []);

    return (
        <section className="chapters">
            <div>
                <h2 className="">{label}</h2>
                <div className="gallery">
                    {events.map(event => (
                        <img
                            key={event.title+label+event.id+event.eventYear}
                            src={`data:image/jpeg;base64,${event.image}`}
                            className="hover-shadow"
                            alt=""
                            onClick={() => setSelectedEvent(event)}
                        />
                    ))}
                </div>
            </div>
            {selectedEvent && (
                <ImageModal
                    event={selectedEvent}
                    image={`data:image/jpeg;base64,${selectedEvent.image}`}
                    onClosePressed={() => setSelectedEvent(undefined)}
                    onPreviousPressed={() => setSelectedEvent(events.find((x, i) => i === events.indexOf(selectedEvent)-1) ?? events[events.length - 1])}
                    onNextPressed={() => setSelectedEvent(events.find((x, i) => i === events.indexOf(selectedEvent)+1) ?? events[0])}
                />
            )}
        </section>
    );
};

export default Chapter;
