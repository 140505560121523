import React from 'react';
import './textInput.css'

interface ITextAreaProps {
    value: string
    onChange: (value: string) => void
    label: string
    placeHolder?: string
}

const TextArea: React.FC<ITextAreaProps> = ({value, onChange, label, placeHolder}) => {
    return (
        <div className="text-input-container">
            <label htmlFor={label} className="inp" title={label}>{label}</label>
            <textarea className="text-input" id={label} value={value} onChange={e => onChange(e.target.value)} placeholder={placeHolder}/>
        </div>
    );
};

export default TextArea;