import React from 'react';

interface IDetailsBoxProps {
    title?: string
    description?: string
    displaySide: "right" | "left"
}

const DetailsBox: React.FC<IDetailsBoxProps> = ({title, description, displaySide}) => {

    const alignmentClass = "details-box-" + displaySide;
    const titleAlignmentClass = "details-title-" + displaySide;

    return (
        <div className={"details-box-container " + alignmentClass}>
            <div className="details-container">
                <div className={"details-title " + titleAlignmentClass}>{title}</div>
                <div>{description}</div>
            </div>
        </div>
    );
};


export default DetailsBox;