import React from 'react';
import {Link, NavLink, useLocation,} from 'react-router-dom';

const Navbar = () => {
    let location = useLocation();

    return (
        <nav className="navbar">
            <div className="title">
                {/*<a href="index.html">Betty Carse</a>*/}
                <NavLink to={"/Home"}>Betty Carse</NavLink>
            </div>
            <div className="navLinks">
                <ul>
                    <li>
                        <NavLink to={"/Home"} className={location.pathname.toLowerCase().includes("home") ? "active" : ""}>Home</NavLink>
                        {/*<a href="index.html" className="active">Home</a>*/}
                    </li>
                    {/*<li>
                        <a href="about.html">About</a>
                    </li>*/}
                    <li>
                        {/*<a href="timeline.html">Timeline</a>*/}
                        <NavLink to={"/Timeline"} className={location.pathname.toLowerCase().includes("timeline") ? "active" : ""}>Timeline</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/Stories"} className={location.pathname.toLowerCase().includes("stories") ? "active" : ""}>Stories</NavLink>
                    </li>
                    <li>
                        {/*<a href="chapters.html">Chapters</a>*/}
                        <NavLink to={"/Chapters"} className={location.pathname.toLowerCase().includes("chapters") ? "active" : ""}>Chapters</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
