import React from 'react';
import Checkbox from "../../components/Checkbox/Checkbox";
import './uploadPage.css';
import {Categories} from "./UploadPage";

interface ICategoriesCheckboxListProps {
    categories: Categories
    setCategories: (c: Categories) => void
}

const CategoriesCheckboxList: React.FC<ICategoriesCheckboxListProps> = ({categories, setCategories}) => {

    const updateCategories = (category: keyof Categories) => {
        setCategories({...categories, ...{[category]: !categories[category]}})
    }

    return (
        <div>
            <label>Categories</label>
            <div className="categories-container">
                <Checkbox checked={categories["Young Betty"]} label={"Young Betty"} onCheckedChanged={() => updateCategories("Young Betty")}/>
                <Checkbox checked={categories["Friends"]} label={"Friends"} onCheckedChanged={() => updateCategories("Friends")}/>
                <Checkbox checked={categories["Work"]} label={"Work"} onCheckedChanged={() => updateCategories("Work")}/>
                <Checkbox checked={categories["Marriage"]} label={"Marriage"} onCheckedChanged={() => updateCategories("Marriage")}/>
                <Checkbox checked={categories["Children"]} label={"Children"} onCheckedChanged={() => updateCategories("Children")}/>
                <Checkbox checked={categories["Grandchildren"]} label={"Grandchildren"} onCheckedChanged={() => updateCategories("Grandchildren")}/>
                <Checkbox checked={categories["Family"]} label={"Family"} onCheckedChanged={() => updateCategories("Family")}/>
            </div>
        </div>
    );
};

export default CategoriesCheckboxList;
