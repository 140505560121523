import React from 'react';

interface IDateBoxProps {
    dayMonth?: string
    year: string
}

const DateBox: React.FC<IDateBoxProps> = ({dayMonth, year}) => {
    return (
        <div className="date-box">
            <div className="date-box-info">
                <div className="date-box-text date-day-month">{dayMonth}</div>
                <div className="date-box-text date-year">{year}</div>
            </div>
        </div>
    );
};

export default DateBox;