const baseURL_Https_Hotspot = "https://172.20.10.8:5006/";
const baseURL_Https_Wifi = "https://192.168.1.42:5006/";
const baseURL_Https_Desktop = "https://localhost:44352/";
const baseURL_Https_Production = "https://bettycarse.com/";

const defaultSettings = {
    apiUrl: baseURL_Https_Production,
}

const allSettings = {
    dev: {
        ...defaultSettings,
    },
    staging: {
        ...defaultSettings,
    },
    prod: {
        ...defaultSettings,
    }
}

const getCurrentSettings = () => {
    return allSettings.dev;
    // return allSettings.prod;
}

export const Settings = getCurrentSettings();
