import React, {useContext, useEffect, useState} from 'react';
import {IEvent} from "../models/Event";
import dayjs from "dayjs";
import {Images} from "../Testing/Images";
import {Utils} from "../helper/utils";
import {Http} from "../helper/Http";

interface ITimelineEventsContext {
    events: IEvent[]
    addEvent: (event: IEvent) => void
    loaded: boolean
}
const TimelineEventsContext = React.createContext<ITimelineEventsContext | null>(null);
export const useTimelineEvents = () => useContext(TimelineEventsContext)!;

const TimelineEventsProvider: React.FC = ({children}) => {
    const [events, setTimelineEvents] = useState<IEvent[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await Http.get<IEvent[]>("Events/TimelineEvents");
            if(result.ok){
                console.log("result:", result);
                console.log("result data:", result.data);
                setTimelineEvents(result.data ?? []);
                console.log("events:", events);
                setLoaded(true);
            }
        })();
    }, [])

    const addEvent = (event: IEvent) => setTimelineEvents(x => [...x, event]);

    return (
        <TimelineEventsContext.Provider value={{events, addEvent, loaded}}>
            {children}
        </TimelineEventsContext.Provider>
    );
};

export default TimelineEventsProvider;
