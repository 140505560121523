
export class Consts {
    static TIME_LINE_LENGTH = 1000; // e.g 1000vh for 100 years
    static TIME_LINE_LENGTH_STYLE = Consts.TIME_LINE_LENGTH + "vh";
    static TIME_LINE_LENGTH_ONE_YEAR = Consts.TIME_LINE_LENGTH / 100; // e.g 10vh for every 1 year
    static START_YEAR = 1922;
    static END_YEAR = 2022;
    static CARD_MAX_HEIGHT = Consts.TIME_LINE_LENGTH_ONE_YEAR * 2;
    static YEAR_TAG_HEIGHT = 19;

    static DAYS = ["Event Day", ...Array(31).fill(undefined).map((_, i) => i+1 >= 10 ? (i+1).toString() : "0" + (i+1))];
    static MONTHS = ["Event Month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    static YEARS = ["Event Year", ...Array(138).fill(undefined).map((_, i) => (i+1885).toString())];
}
export const CreatedByCode = {
    CARSE: "1090",
    JEFF: "1555",
    JAN: "1555",
}
