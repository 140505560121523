import { LoremIpsum } from "lorem-ipsum";

export class Utils {

    static lorem = new LoremIpsum({
        sentencesPerParagraph: {
            max: 6,
            min: 1
        },
        wordsPerSentence: {
            max: 10,
            min: 4
        }
    });

    static randomInt(min: number, max: number) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    static generateLoremIpsumWords(maxWords: number) {
        return this.lorem.generateWords(this.randomInt(1, maxWords));
    }

    static async getBase64(file: Blob): Promise<string | null | ArrayBuffer> {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(null);
        });
    }

    static async compressImage2(file: File) {
        let imgSizeReader = new FileReader();
        imgSizeReader.onload = function (e) {

            let ogImg = new Image();
            ogImg.onload = function() {
                let newWidth = ogImg.width;
                let newHeight = ogImg.height;

                while(newWidth > 800 && newHeight > 800){
                    newWidth = newWidth * 0.95;
                    newHeight = newHeight * 0.95;
                }

                newWidth = Math.floor(newWidth);
                newHeight = Math.floor(newHeight);

                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    const img = new Image();
                    img.onload = () => {
                        const elem = document.createElement('canvas');
                        elem.width = newWidth;
                        elem.height = newHeight;
                        const ctx = elem.getContext('2d');
                        if(ctx){
                            ctx.drawImage(img, 0, 0, newWidth, newHeight);
                            return elem.toDataURL('image/jpeg', 0.9).replace(/^data:.+;base64,/, '');
                        }
                    }
                    if(typeof reader.result === "string")
                        img.src = reader.result;
                };
            };

            if(typeof imgSizeReader.result === "string")
                ogImg.src = imgSizeReader.result;
        };
        imgSizeReader.readAsDataURL(file);
    }

    static compressImage(file: File) {
        return new Promise((resolve, reject) => {
            let imgSizeReader = new FileReader();
            imgSizeReader.onload = function (e) {

                let ogImg = new Image();
                ogImg.onload = function() {
                    let newWidth = ogImg.width;
                    let newHeight = ogImg.height;

                    while(newWidth > 800 && newHeight > 800){
                        newWidth = newWidth * 0.95;
                        newHeight = newHeight * 0.95;
                    }

                    newWidth = Math.floor(newWidth);
                    newHeight = Math.floor(newHeight);

                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        const img = new Image();
                        img.onload = () => {
                            const elem = document.createElement('canvas');
                            elem.width = newWidth;
                            elem.height = newHeight;
                            const ctx = elem.getContext('2d');
                            if(ctx){
                                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                                return elem.toDataURL('image/jpeg', 0.9).replace(/^data:.+;base64,/, '');
                            }
                        }
                        if(typeof reader.result === "string")
                            img.src = reader.result;
                    };
                };

                if(typeof imgSizeReader.result === "string")
                    ogImg.src = imgSizeReader.result;
            };
            imgSizeReader.readAsDataURL(file);
        });
    }

    static getBase64ImageSize(base64: string) {
        const stringLength = base64.length;
        const sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
        const sizeInKb = sizeInBytes/1000;

        return sizeInKb;
    }
}