import React from 'react';
import keperraImg from '../../img/keperra.jpg';
import poemImg from '../../img/poem.jpg';
import popsImg from '../../img/pops.jpg';
import workImg from '../../img/work.jpg';

const StoriesPage = () => {
    return (
        <section>
            <div className="gallery stories">
                <img src={poemImg}/>
                <img src={popsImg}/>
                <img src={keperraImg}/>
                <img src={workImg}/>
            </div>
        </section>
    );
};

export default StoriesPage;
