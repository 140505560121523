import React, {useContext, useEffect, useState} from 'react';
import {IEvent} from "../models/Event";
import dayjs from "dayjs";
import {Images} from "../Testing/Images";
import {Utils} from "../helper/utils";
import {Http} from "../helper/Http";

interface IEventsContext {
    events: IEvent[]
    addEvent: (event: IEvent) => void
    loaded: boolean
}
const EventsContext = React.createContext<IEventsContext | null>(null);
export const useEvents = () => useContext(EventsContext)!;

const EventsProvider: React.FC = ({children}) => {
    const [events, setEvents] = useState<IEvent[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await Http.get<IEvent[]>("Events/");
            if(result.ok){
                setEvents(result.data ?? []);
                setLoaded(true);
            }
        })();
    }, [])

    const addEvent = (event: IEvent) => setEvents(x => [...x, event]);

    return (
        <EventsContext.Provider value={{events, addEvent, loaded}}>
            {children}
        </EventsContext.Provider>
    );
};

export default EventsProvider;
