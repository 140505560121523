import React, {useState} from 'react';
import Chapter from "./Chapter";
import {useEvents} from "../../providers/EventsProvider";

const ChaptersPage = () => {

    const {events, loaded} = useEvents();
    const [selectedTag, setSelectedTag] = useState<string | null>(null);

    if(!loaded) {
        return (
            <div className="container">
                <div>Loading</div>
            </div>
        );
    }

    return (
        <>
            <section className="chapter-nav">
                <div>
                    <button onClick={() => setSelectedTag("Young Betty")}>Youth</button>
                    <button onClick={() => setSelectedTag("Marriage")}>Marriage</button>
                    <button onClick={() => setSelectedTag("Family")}>Family</button>
                    <button onClick={() => setSelectedTag("Children")}>Children</button>
                    <button onClick={() => setSelectedTag("Grandchildren")}>Grandchildren</button>
                    <button onClick={() => setSelectedTag("Friends")}>Friends</button>
                    <button onClick={() => setSelectedTag("Work")}>Work</button>
                </div>
            </section>
            {(!selectedTag || selectedTag === "Young Betty") && <Chapter allEvents={events} label="Young Betty" tags={["Young Betty"]}/>}
            {(!selectedTag || selectedTag === "Marriage") && <Chapter allEvents={events} label="Marriage" tags={["Marriage"]}/>}
            {(!selectedTag || selectedTag === "Family") && <Chapter allEvents={events} label="Family" tags={["Family"]}/>}
            {(!selectedTag || selectedTag === "Children") && <Chapter allEvents={events} label="Children" tags={["Children"]}/>}
            {(!selectedTag || selectedTag === "Grandchildren") && <Chapter allEvents={events} label="Grandchildren" tags={["Grandchildren"]}/>}
            {(!selectedTag || selectedTag === "Friends") && <Chapter allEvents={events} label="Friends" tags={["Friends"]}/>}
            {(!selectedTag || selectedTag === "Work") && <Chapter allEvents={events} label="Work" tags={["Work"]}/>}
            {(!selectedTag || selectedTag === "Other") && <Chapter allEvents={events} label="Other" tags={[]} noTags={true}/>}

        </>
    );
};

export default ChaptersPage;
