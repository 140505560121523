import React from 'react';
import './dropDownList.css';

interface IDropDownListProps {
    values: string[] | number[]
    onValueChanged: (value: string) => void
    value?: string
}

const DropDownList: React.FC<IDropDownListProps> = ({values, onValueChanged, value}) => {
    if(value)
        return (
            <select className="drop-down" onChange={e => onValueChanged(e.target.value)} value={value}>
                {values.map(x => <option key={x} value={x}>{x}</option>)}
            </select>
        );
    return (
        <select className="drop-down" onChange={e => onValueChanged(e.target.value)}>
            {values.map(x => <option key={x} value={x}>{x}</option>)}
        </select>
    );
};

export default DropDownList;
