import React, {ChangeEvent, useRef} from 'react';
import './uploadArea.css';

interface IUploadAreaProps {
    onFilesUploaded: (e: ChangeEvent<HTMLInputElement>) => void
}

const UploadArea: React.FC<IUploadAreaProps> = ({onFilesUploaded}) => {

    const inputRef = useRef<HTMLInputElement>(null);
//onClick={() => inputRef.current?.click()}
    return (
        <div className="upload-area-container" >
            <input ref={inputRef} type="file" accept="image/*" name="image" multiple onChange={onFilesUploaded}/>
            <span>Drag and drop images or click to upload.</span>
        </div>
    );
};


export default UploadArea;