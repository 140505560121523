import React, {MouseEventHandler} from 'react';
import {IEvent} from "../models/Event";

interface IImageModalProps {
    image: string | undefined
    event: IEvent
    onClosePressed: () => void
    onPreviousPressed: () => void
    onNextPressed: () => void
}

const ImageModal: React.FC<IImageModalProps> = ({image, onClosePressed, onPreviousPressed, onNextPressed, event}) => {
    if(!image)
        return null;

    const handlePrevPressed = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onPreviousPressed();
        return false;
    }
    const handleNextPressed = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onNextPressed();
        return false;
    }

    return (
        <div id="myModal" className="modal" onClick={onClosePressed}>
            <span className="close cursor" onClick={onClosePressed}>&times;</span>
            <div className="modal-content">
                <div id="modal-img" className="mySlides">
                    <img src={image}/>
                </div>
                <a className="prev" onClick={e => handlePrevPressed(e)}>&#8249;</a>
                <a className="next" onClick={e => handleNextPressed(e)}>&#8250;</a>
                <div className="details">
                    <h3 id="img-title">{event.title} - {event.eventDay ? event.eventDay + " " : ""}{event.eventMonth ? event.eventMonth + " " : ""}{event.eventYear}</h3>
                    <p id="img-description">
                        {event.description}
                    </p>
                    <p>
                        <i>In the photo: <span id="img-caption">{event.caption}</span></i>
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <div id="myModal" className="modal" onClick={onClosePressed}>
            <span className="close cursor" onClick={onClosePressed}>&times;</span>
            <div className="modal-content">
                <div id="modal-img" className="mySlides" style={{display: "flex", flexDirection: "column"}}>
                    <img src={image}/>
                    <span style={{fontWeight: "bold"}}>{event.title} - {event.eventDay ? event.eventDay + " " : ""}{event.eventMonth ? event.eventMonth + " " : ""}{event.eventYear}</span>
                    <span>{event.description}</span>
                    <span style={{color: "#777", fontSize: "0.9em"}}>{event.caption}</span>
                </div>
                <a className="prev" onClick={e => handlePrevPressed(e)}>&#10094;</a>
                <a className="next" onClick={e => handleNextPressed(e)}>&#10095;</a>
            </div>
        </div>
    );
};

export default ImageModal;
