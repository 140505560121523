import React from 'react';
import './homePage.css'
import './homePage.css';
import heroImg from '../../img/hero.jpg';
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <section className="hero">
            <img src={heroImg} alt="Main Image"/>
            <div className="home">
                <h1>Celebrating a hundred years of life.</h1>
                <button onClick={() => navigate('/Timeline')}>Explore</button>
            </div>
        </section>
    );
};

export default HomePage;
