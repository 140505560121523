import React, {useEffect, useState} from 'react';
import {useEvents} from "../../providers/EventsProvider";
import {Utils} from "../../helper/utils";
import {IEvent} from "../../models/Event";
import {Http} from "../../helper/Http";
import * as ImgUtil from "image-conversion";

const FileSizesPage = () => {

    const {events: allEvents, loaded} = useEvents();
    const [events, setEvents] = useState(allEvents);

    useEffect(() => {
        setEvents(allEvents)
    }, [allEvents]);

    const compress = async (event: IEvent) => {

        const existingBase64 = `data:image/jpeg;base64,${event.image}`;
        const existingBlob = await ImgUtil.urltoBlob(existingBase64);
        const compressedImage = await ImgUtil.compressAccurately(existingBlob,400);

        let base64 = await Utils.getBase64(compressedImage);
        if(typeof base64 === "string")
            base64 = base64.substring(base64.indexOf(",") + 1);
        else{
            alert("something went wrong uploading this image");
            return;
        }

        console.log("Old Image Size:", Utils.getBase64ImageSize(existingBase64))
        console.log("new Image Size:", Utils.getBase64ImageSize(base64))

        setEvents(events.filter(x => x.id !== event.id));
        await Http.post("Events/NewImage", {id: event.id, image: base64});
    }

    if(!loaded) {
        return (
            <div className="container">
                <div>Loading</div>
            </div>
        );
    }

    return (
        <div className="container">
            {events.map((event, i) => {
                const imageSize = Math.round(Utils.getBase64ImageSize(event.image));
                if(imageSize < 500)
                    return null;
                return (
                    <div key={event.id!.toString()} style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                        <img src={`data:image/jpeg;base64,${event.image}`} alt="Thumbnail" className="timeline-image"/>
                        <span style={{marginLeft: "50px", fontWeight: imageSize > 500 ? "bold" : "normal"}}>{imageSize} kb</span>
                        <button style={{marginLeft: "50px"}} onClick={() => compress(event)}>Compress</button>
                    </div>
                )
            })}
        </div>
    );
};

export default FileSizesPage;
