import React, { Suspense } from 'react';
import {Route, useLocation, Routes} from 'react-router-dom';
import './App.css';
import EventsProvider from "./providers/EventsProvider";
import HomePage from "./pages/HomePage/HomePage";
import UploadPage from "./pages/UploadPage/UploadPage";
import DeletePage from "./pages/DeletePage/DeletePage";
import TimelineEventsProvider from "./providers/TimelineEventsProvider";
import FileSizesPage from "./pages/FileSizesPage/FileSizesPage";
import Timeline from "./components/Timeline";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ChaptersPage from "./pages/ChaptersPage/ChaptersPage";
import StoriesPage from "./pages/StoriesPage/StoriesPage";

function App() {
  return (
      <EventsProvider>
        <TimelineEventsProvider>
            <main style={{backgroundColor: "white"}}>
                <Navbar/>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        {/*<Route path="/not-found" component={NotFound}/>*/}
                        {/*<Route path="/Logout" component={Logout}/>*/}
                        {/*<Route path="/Login" component={Login}/>*/}
                        <Route path="/fileSizes" element={<FileSizesPage/>}/>
                        <Route path="/upload" element={<UploadPage/>}/>
                        <Route path="/delete" element={<DeletePage/>}/>
                        <Route path="/edit" element={<DeletePage/>}/>
                        <Route path="/Timeline" element={<Timeline/>}/>
                        <Route path="/Home" element={<HomePage/>}/>
                        <Route path="/Stories" element={<StoriesPage/>}/>
                        <Route path="/Chapters" element={<ChaptersPage/>}/>
                        <Route path="/" element={<HomePage/>}/>
                    </Routes>
                </Suspense>
                <Footer/>
            </main>
        </TimelineEventsProvider>
      </EventsProvider>
  );
}

export default App;
